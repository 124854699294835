import { createEffect, createSignal, Show } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";

type VoucherCardFieldProps = {
  fieldName: string;
  fieldValue: string;
};

export function VoucherCardField(props: VoucherCardFieldProps) {
  const [copied, setCopied] = createSignal<boolean>(false);

  createEffect(() => {
    copied();

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  });

  return (
    <div class="inline-flex h-[37px] items-center justify-start gap-4 self-stretch">
      <div class="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
        <div class=" text-medium text-baseSecondaryMedium">
          {props.fieldName}
        </div>
        <div class="text-bold text-textDark">{props.fieldValue}</div>
      </div>
      <Show
        when={!copied()}
        fallback={
          <div class="cursor-pointer text-center  text-[13px] font-semibold leading-[18.20px] text-green-600">
            Copied!
          </div>
        }
      >
        <div
          class="inline-flex h-[26px] w-[63px] cursor-pointer items-center justify-start gap-1 "
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(props.fieldValue);
            console.log("copied pin");
          }}
        >
          <div class="relative text-center text-bold text-basePrimaryDark">
            Copy
          </div>

          <div class="relative h-[26px] w-[26px]">
            <PhosphorIcon name="copy" fontSize={26} />
          </div>
        </div>
      </Show>
    </div>
  );
}
